// import ImgClaudeAI from '../../../../../../assets/images/claudeai.png';
// import ImgGemini from '../../../../../../assets/images/gemini.png';
// import ImgNemotron from '../../../../../../assets/images/nemotron.png';
// import ImgOrca2 from '../../../../../../assets/images/orca2.png';
import { IconInfo } from '../../../../../components/public/Icons';
import { CustomTooltip } from '../../../../../components/ui/CustomTooltip';
import React, { useEffect, useState, JSX } from 'react';
import IngestionService from '../../../../../services/ingestion.service';
import useAction from '../../../../../components/hooks/useAction';
import config from '../../../../../../config';
import axios from 'axios';
import { PortfolioProps } from '../../../../../../@types/Widget';
import InfiniteScroll from 'react-infinite-scroll-component';
import KnowledgeBaseTextarea from './customComponents/KnowledgeBaseTextarea';
import DataLoader from '../../../../../components/loaders/DataLoader';
import { useSelector } from 'react-redux';
import { userDetailsData } from '../../../../../redux/selector/UserDetailsSelector';
import UploadProgressModal from '../../../../../components/loaders/UploadProgressModal';
import CustomSelect from '../../../../../components/ui/CustomSelect';

const MergeOptions = [
  { label: 'Union', value: 'union' },
  { label: 'Join', value: 'join' },
];

type ModelListTypes = {
  _id: string;
  model_name: string;
  display_name: string;
};

type selectedDataSourceType = {
  file: string;
  subFile: string;
  type: string;
};

type SelectFilesPropsTypes = {
  isUpdate: boolean;
  reduxSelectedPortfolios: string[];
  setSelectedKnowledgeBaseFile: any;
  selectedPortfolio: string[];
  selectedKnowledgeBaseFile: {
    fileName: string;
    jsonData: string;
  }[];
  setSelectedPortfolio: React.Dispatch<React.SetStateAction<string[]>>;
  selectedDataSourcesWidget: selectedDataSourceType[];
  setSelectedDataSourcesWidget: React.Dispatch<
    React.SetStateAction<selectedDataSourceType[]>
  >;
  selectedMergeType: {
    label: string;
    value: string;
  };
  setSelectedMergeType: React.Dispatch<
    React.SetStateAction<{
      label: string;
      value: string;
    }>
  >;
};

const SelectFiles = ({
  isUpdate,
  reduxSelectedPortfolios,
  setSelectedKnowledgeBaseFile,
  selectedKnowledgeBaseFile,
  selectedPortfolio,
  setSelectedPortfolio,
  selectedDataSourcesWidget,
  setSelectedDataSourcesWidget,
  selectedMergeType,
  setSelectedMergeType,
}: SelectFilesPropsTypes) => {
  const [isMainLoading, setIsMainLoading] = useState(false);
  const [isKnowledgebaseLoading, setIsKnowledgebaseLoading] = useState(false);
  const [fileNamesPage, setFileNamesPage] = useState(1);
  const [portfolio, setPortfolio] = useState<PortfolioProps>(
    {} as PortfolioProps,
  );

  const [knowledgeBaseFiles, setKnowledgeBaseFiles] = useState(['']);

  const ingService = new IngestionService();
  const [modelList, setModelList] = useState<
    Array<{
      label: JSX.Element;
      value: string;
    }>
  >([]);
  const userDetails = useSelector(userDetailsData);

  const { showModal } = useAction();

  const [isModalOpen, setModalOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const toggleModal = () => setModalOpen(!isModalOpen);

  const handleUploadKnowledgetBaseFile = async (e: any) => {
    e.preventDefault();
    setIsMainLoading(true);
    const file = e.target.files[0];

    if (file) {
      setIsMainLoading(true);

      try {
        const formData = new FormData();

        formData.append('file', file);

        await axios({
          method: 'post',
          url: `${config.ER_ML_URL}/file/`,
          data: formData,
        }).then(() => {
          showModal('Uploaded successfully!', true);
          getKnowledgeBaseFiles();
          setIsMainLoading(false);
        });
      } catch (e) {
        setIsMainLoading(false);
        showModal('something went wrong');
      }
    }
    e.target.value = null;
  };

  const handleUploadFile = async (e: any) => {
    e.preventDefault();
    const files = e.target.files;

    if (files && files.length > 0) {
      setIsMainLoading(true);
      try {
        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const sizeInBytes = file.size;
          let fileSize: string;

          if (sizeInBytes >= 1024 * 1024 * 1024) {
            fileSize = (sizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
          } else if (sizeInBytes >= 1024 * 1024) {
            fileSize = (sizeInBytes / (1024 * 1024)).toFixed(2) + ' MB';
          } else if (sizeInBytes >= 1024) {
            fileSize = (sizeInBytes / 1024).toFixed(2) + ' KB';
          } else {
            fileSize = sizeInBytes + ' Bytes';
          }

          formData.append(`uploaded_files[${i}]`, file);
          formData.append(`sizes[${i}]`, fileSize);
        }

        setModalOpen(true);
        setUploadProgress(0);

        let progress = 0;
        const interval = setInterval(() => {
          progress += 100 / (5 * 10);
          if (progress >= 100) {
            clearInterval(interval);
            setUploadProgress(100);
          } else {
            setUploadProgress(Math.min(progress, 100));
          }
        }, 100);

        await ingService.uploadCreditWidgetFile(formData, {
          onUploadProgress: () => {},
        });

        showModal('Files uploaded successfully!', true);
        getPortfolio(false, true);
        setModalOpen(false);
      } catch (error) {
        setIsMainLoading(false);
        setModalOpen(false);
        showModal('Something went wrong');
      }
    }

    e.target.value = null;
  };

  const getKnowledgeBaseFiles = async () => {
    setIsKnowledgebaseLoading(true);
    try {
      const response = await axios({
        method: 'get',
        url: `${config.ER_ML_URL}/file/list`,
      });
      const responseManipulation = await response?.data?.data?.files;

      setKnowledgeBaseFiles(responseManipulation);
      setIsKnowledgebaseLoading(false);
    } catch (error) {
      showModal('something went wrong');
      setIsKnowledgebaseLoading(false);
    } finally {
      setIsKnowledgebaseLoading(false);
    }
  };

  const getPortfolio = async (isPageChange: boolean, isUpload?: boolean) => {
    setIsMainLoading(true);

    try {
      const response = await axios({
        method: 'get',
        url: `${config.ER_ML_URL}/portfolio/?limit=10&page=${
          isUpload ? 1 : fileNamesPage
        }`,
      });
      const responseManipulation = !isPageChange
        ? response.data
        : Object.keys(portfolio).length > 0
        ? {
            ...portfolio,
            data: [...(portfolio?.data || {}), ...(response.data.data || {})],
          }
        : response.data;

      setPortfolio(responseManipulation);
      if (isPageChange && !isUpload) {
        setFileNamesPage(fileNamesPage + 1);
      } else {
        setFileNamesPage(1 + 1);
      }

      setIsMainLoading(false);
    } catch (error) {
      showModal('something went wrong');
      setIsMainLoading(false);
    } finally {
      setIsMainLoading(false);
    }
  };

  const getFoundationalModelList = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: `${config.ER_ML_URL}/llm/list`,
      });
      const responseManipulation: ModelListTypes[] = response?.data?.data;
      let updatedRes = responseManipulation?.map(x => {
        return {
          label: (
            <div className="select-img">
              {/* <img
                src={ImgOrca2}
                height="16"
                width="16"
                className="img-cover"
              /> */}
              <span>{x.display_name}</span>
            </div>
          ),
          value: x?.display_name,
        };
      });

      setModelList(updatedRes);
    } catch (error) {
      showModal('something went wrong');
    }
  };

  useEffect(() => {
    if (userDetails?._id) getFoundationalModelList();
  }, [userDetails?._id]);

  useEffect(() => {
    getKnowledgeBaseFiles();
    getPortfolio(true);

    if (isUpdate) {
      setSelectedPortfolio(reduxSelectedPortfolios);
    }
  }, []);

  return (
    <>
      <div className="addwidget-content mw-100">
        <div className="form-group flexbox-c mb-3 gap-2">
          <label className="form-label mb-0">Merge Type</label>
          <div className="min-w-150">
            <CustomSelect
              options={MergeOptions}
              value={selectedMergeType}
              isSearchable={false}
              render
              onChange={(e: { label: string; value: string }) => {
                setSelectedMergeType(e);
              }}
            />
          </div>
        </div>
        <div className="row row-divider justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="d-flex align-items-center">
              <p className="widget-category me-1 mb-0">SELECT PORTFOLIO(S)</p>
              <span className="d-flex" id="tooltip13">
                <IconInfo />
              </span>
              <CustomTooltip placement="top" target="tooltip13" />
              <input
                type="file"
                className="d-none"
                id="uploadportfolio"
                onChange={handleUploadFile}
                multiple
              />
              <label htmlFor="uploadportfolio" className="ms-3 link">
                Upload new portfolio
              </label>
            </div>
            <div
              className="create-widget wl-440 create-widget-portfolio"
              id="scrollableDiv"
            >
              <div className="widget-item">
                <div className="row row-cols-1">
                  <InfiniteScroll
                    dataLength={portfolio?.data?.length || 0}
                    next={() => getPortfolio(true)}
                    hasMore={portfolio?.data?.length <= 0 ? false : true}
                    loader={
                      portfolio?.data?.length <= 0 ? (
                        <div style={{ paddingLeft: '12px' }}>Loading...</div>
                      ) : null
                    }
                    scrollableTarget="scrollableDiv"
                  >
                    {portfolio?.data &&
                      portfolio?.data.length > 0 &&
                      portfolio?.data?.map((item, index) => {
                        return (
                          <div className="col" key={index}>
                            <input
                              type="checkbox"
                              name="select-portfolio"
                              checked={
                                selectedDataSourcesWidget?.findIndex(
                                  x => x?.file === item?.fileName,
                                ) >= 0
                              }
                              onClick={() => {
                                const isExist = selectedPortfolio.find(
                                  ele => ele === item._id,
                                );

                                let tempSelectedArray = [
                                  ...selectedDataSourcesWidget,
                                ];

                                const portfolioIndex = tempSelectedArray?.findIndex(
                                  val => val?.file === item?.fileName,
                                );

                                if (portfolioIndex >= 0) {
                                  tempSelectedArray.splice(portfolioIndex, 1);
                                } else {
                                  tempSelectedArray.push({
                                    file: item?.fileName,
                                    subFile: '',
                                    type: 'PORTFOLIO',
                                  });
                                }

                                setSelectedDataSourcesWidget?.(
                                  tempSelectedArray,
                                );

                                if (isExist) {
                                  const filterIsExist = selectedPortfolio.filter(
                                    selected => selected !== item._id,
                                  );

                                  setSelectedPortfolio(filterIsExist);
                                } else {
                                  const duplicate = [...selectedPortfolio];

                                  duplicate.push(item._id);

                                  setSelectedPortfolio(duplicate);
                                }
                              }}
                            />
                            <div className="widget-card">
                              <p>
                                {item.saveName ? item.saveName : item.fileName}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </InfiniteScroll>
                  {isMainLoading && <DataLoader />}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="d-flex align-items-center">
              <p className="widget-category me-1 mb-0">
                SELECT KNOWLEDGE BASE ITEMS
              </p>
              <span className="d-flex" id="tooltip14">
                <IconInfo />
              </span>
              <CustomTooltip placement="top" target="tooltip14" />
              <input
                type="file"
                className="d-none"
                id="uploadKnowledgeportfolio"
                onChange={handleUploadKnowledgetBaseFile}
              />
              <label htmlFor="uploadKnowledgeportfolio" className="ms-3 link">
                Upload new file
              </label>
            </div>
            <div className="create-widget wl-440 create-widget-portfolio">
              <div className="widget-item">
                <div className="row row-cols-1">
                  {knowledgeBaseFiles?.map((knowFiles, knowIndex) => (
                    <KnowledgeBaseTextarea
                      knowFiles={knowFiles}
                      knowIndex={knowIndex}
                      roleOptions={modelList}
                      selectedKnowledgeBaseFile={selectedKnowledgeBaseFile}
                      setSelectedKnowledgeBaseFile={
                        setSelectedKnowledgeBaseFile
                      }
                      selectedDataSourcesWidget={selectedDataSourcesWidget}
                    />
                  ))}
                  {isKnowledgebaseLoading && <DataLoader />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UploadProgressModal
        modal={isModalOpen}
        toggle={toggleModal}
        progress={uploadProgress}
      />
    </>
  );
};

export default SelectFiles;
