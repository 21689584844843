import ApiUtil from '../../utils/apiUtil';

export default class IngestionService {
  async dataIngestion(payload: any): Promise<any> {
    const url = '/ingestion';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  } // not used

  async getWarrenGroupFilesList(payload: any): Promise<any> {
    const url = `/warrenGroup?page=${payload.page}&limit=${payload.limit}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async mergeWarrenGroupFiles(payload: any): Promise<any> {
    const url = '/warrenGroup';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async getExrIngestionData(key: string): Promise<any> {
    const url = `/ingestion/?key=${key}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getUploadedFilesName(): Promise<any> {
    const url = `/portfolio`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  } //not used

  async getUploadedFileDetails(key: any): Promise<any> {
    const url = `/portfolio/${key}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  } //not used

  async getAllFiles(payload: any): Promise<any> {
    const url = `/file?page=${payload.page}&limit=${payload.limit}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getAllFilesById(id: string): Promise<any> {
    const url = `/file/${id}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async getUploadedFile(id: string): Promise<any> {
    const url = `/file/getUploadFile/${id}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  } //not used

  async uploadCreditWidgetFile(payload: any, config: any = {}): Promise<any> {
    const url = `/v1/uploadFile`;
    // const customUrl = `http://localhost:3001/api/v1/uploadFile`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
      ...config,
    });
    return res.data;
  }

  async searchUser(searchValue: any): Promise<any> {
    const url = `/userDetails/search?search=${searchValue}`;
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    });
    return res.data;
  }

  async sharePortfolio(payload: any): Promise<any> {
    const url = '/file/sharePortfolio';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async shareNewsWidget(payload: any): Promise<any> {
    const url = '/file/shareTaskWidget';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async shareVisualisationWidget(payload: any): Promise<any> {
    const url = '/file/shareVisualisationWidget';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async shareRejectionStatus(payload: any): Promise<any> {
    const url = '/file/shareRejectionStatus';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async shareCreditWidget(payload: any): Promise<any> {
    const url = '/file/shareCreditWidget';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }

  async shareStatisticalWidget(payload: any): Promise<any> {
    const url = '/file/shareStatisticalWidget';
    const res = await ApiUtil.SendAsync<any>({
      url: url,
      method: 'POST',
      data: payload,
    });
    return res.data;
  }
}
