import classnames from 'classnames';
import * as React from 'react';
//import { visuallyHidden } from '@mui/utils';
import { useSelector } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import useAction from '../../../../../components/hooks/useAction';
// import SessionModal from '../../../../../components/modals/SessionModal';
import {
  IconChatbot,
  Iconclose,
  IconCross,
  IconDataSource,
  IconFullScreen,
  IconInfo,
  IconSearch,
  IconSort,
  // IconStar,
  IconTick,
} from '../../../../../components/public/Icons';
import { callTelemetryRecord } from '../../../../../helpers/utils.helper';
import { widgetRankData } from '../../../../../redux/selector/WidgetRankSelector';
import { v4 as uuidv4 } from 'uuid';
// import PublicPagination from '../../../../../components/public/Pagination';
import PublicChat from '../../../../../components/public/PublicChat';
import SendWidgetAsTask from '../../../newsfeed/widgets/SendWidgetAsTask';
import ShareWidgetExternally from '../../../newsfeed/widgets/ShareWidgetExternally';
import ExportWidgeTData from '../../../newsfeed/widgets/ExportWidgeTData';
import RemoveWidget from '../../../newsfeed/widgets/RemoveWidget';
import WidgetMenuDropdown from '../../../../../components/ui/WidgetMenuDropdown';
import { CustomTooltip } from '../../../../../components/ui/CustomTooltip';
import axios from 'axios';
import config from '../../../../../../config';
import { allPortfolioWidgetsSelector } from '../../../../../redux/selector/PortfolioWidgetsSelector';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isAxiosError } from '../../../../../redux/constant';
import WidgetLoader from '../../../../../components/loaders/WidgetLoader';
import { widgetReferenceIdData } from '../../../../../redux/selector/WidgetReferenceIdSelector';
import { searchedChatQuery } from '../../../../../redux/selector/ChatQuerySelector';
import { getSelectedModel } from '../../../../../redux/selector/SelectedModelSelector';
import exportToCSV from '../../../../../components/features/ExportCsv';
import UpdateMapPortfolioData from './UpdateMapPortfolioData';
import useDebounce from '../../../../../components/hooks/useDebounce';

type selectedDataSourceType = {
  file: string;
  subFile: string;
  type: string;
};

export default function CreditWidgetCopy({
  creditIndex,
  onClose,
  toggleWidgetWorkflow,
  setWidgetIndex,
  setWidgetTypeToUpdate,
  widgetFullScreen,
  setWidgetFullScreen,
}: any) {
  const widRank = useSelector(widgetRankData);
  const [isLoading, setIsLoading] = React.useState(false);
  const widgetReferenceIdValue = useSelector(widgetReferenceIdData);
  const chatQueryData = useSelector(searchedChatQuery);
  const selectedModelChat = useSelector(getSelectedModel);

  // const [showSessionModal, setShowSessionModal] = React.useState(false);

  const [isChatOpen, setIsChatOpen] = React.useState(false);
  const toggleWidgetFullScreen = (type?: string) => {
    document.body.classList.toggle('widget-fullscreen');
    setWidgetFullScreen((widgetFullScreen: any) => !widgetFullScreen);
    setIsChatOpen(false);

    // fullscreen widget go to top on fullscreen click
    const pagecontent = document.querySelector('.page-content');
    if (pagecontent) {
      pagecontent.scroll({ top: 0 });
    }

    if (type === 'add') {
      document.body.classList.add(`widget-${creditIndex}`);
      document.body.classList.add('widget-fullscreen');

      const addElement = document.getElementById(`fs${creditIndex}`);
      if (addElement) {
        const parentElement = addElement.closest('.react-grid-item');
        if (parentElement) {
          setTimeout(() => {
            //
            parentElement.classList.add('widget-active');
          }, 10);
        }
      }
    } else if (type === 'remove') {
      document.body.classList.remove(`widget-${creditIndex}`);
      document.body.classList.remove('widget-fullscreen');

      const removeElement = document.getElementById(`fsrem${creditIndex}`);
      if (removeElement) {
        const parentElement = removeElement.closest('.react-grid-item');
        if (parentElement) {
          parentElement.classList.remove('widget-active');
        }
      }
    }
  };

  const {
    addWidgetType,
    removePortfolioCreditWidgetData,
    showModal,
    addChatQuery,
    addPortfolioCreditWidgetData,
    addIndependentWidgetReferenceId,
    removeIndependentWidgetReferenceId,
    addWidgetReferenceId,
  } = useAction();

  const allPortfolioWidgetReduxData = useSelector(allPortfolioWidgetsSelector);
  const creditWidgetData = allPortfolioWidgetReduxData[creditIndex];
  // const firstRender = React.useRef(true);

  // const creditHeaders = Object.keys(creditWidgetData?.widgetData[0]);
  const [creditWidgetPortfolioData, setCreditWidgetPortfolioData] =
    React.useState([] as any);
  const [isUpdateAddItem, setIsUpdateAddItem] = React.useState(false);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [isNormalize, setIsNormalize] = React.useState(false);

  const [widgetName, setWidgetName] = React.useState(
    'Credit (My Credit Calc Workbench)',
  );
  const [isWidgetNameChanged, setIsWidgetNameChanged] = React.useState(false);

  const [isCalculated, setIsCalculated] = React.useState(false);
  const [isRefresh, setIsRefresh] = React.useState(false);

  const [searchQuery, setSearchQuery] = React.useState('');
  const debouncedUserInput = useDebounce(searchQuery, 1000);

  const [divHeight, setDivHeight] = React.useState<number>(0);
  const divRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setDivHeight(entry.contentRect.height);
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  const toggleIsNormalize = () => {
    setIsNormalize(!isNormalize);
  };

  const toogleFilterModal = () => {
    // document.body.classList.toggle('has-overlay');
    document.body.classList.add('show-widget-chat');
  };

  const [dropdownOpen, setDropDownOpen] = React.useState(false);
  const toggleDropDown = () => {
    setDropDownOpen((state) => !state);
  };

  //open modal - Widget As Task
  const [sendWidgetAsTaskModal, setSendWidgetAsTaskModal] =
    React.useState(false);
  const toggleSendWidgetAsTaskModal = () =>
    setSendWidgetAsTaskModal(!sendWidgetAsTaskModal);

  //open modal - Share Externally
  const [shareExternallyModal, setShareExternallyModal] = React.useState(false);
  const toggleShareExternallyModal = () =>
    setShareExternallyModal(!shareExternallyModal);

  //open modal - Export WidgeT Data
  const [exportWidgeTDataModal, setExportWidgeTDataModal] =
    React.useState(false);
  const toggleExportWidgeTDataModal = () =>
    setExportWidgeTDataModal(!exportWidgeTDataModal);

  //remove modal - remove widget
  const [removeWidgetModal, setRemoveWidget] = React.useState(false);
  const toggleRemoveWidgetModal = () => {
    setRemoveWidget(!removeWidgetModal);
  };

  const removeCreditWidget = () => {
    onClose();
    if (widgetFullScreen) {
      toggleWidgetFullScreen('remove');
    }

    removePortfolioCreditWidgetData(creditIndex);
    if (
      widRank &&
      widRank.widgetRank &&
      widRank?.widgetRank?.slots?.length > 0
    ) {
      callTelemetryRecord(
        'widgets',
        widRank?.widgetRank?.event_id,
        widRank?.widgetRank?.slots[0]?.id,
        ['close'],
      );
    }
  };

  const [widgetCurrentActiveTab, setWidgetCurrentActiveTab] =
    React.useState('WidgetData');

  const savedType = async (data: string, saveName: string) => {
    try {
      const payload: {
        portfolio_id: string;
        type: string;
        saveName: string;
      } = {
        portfolio_id: creditWidgetData?.portfolio_id,
        type: data,
        saveName: saveName,
      };
      setIsLoading(true);

      await axios({
        method: 'post',
        url: `${config.ER_ML_URL}/portfolio/save-portfolio`,
        data: payload,
      });
      showModal('Saved successfully!', true);
      setIsLoading(false);
    } catch (err) {
      if (isAxiosError(err)) {
        showModal(
          (err.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
        setIsLoading(false);
      } else {
        showModal('something went wrong');
        setIsLoading(false);
      }
    }
  };

  const getHiddenColumns = (portfoliosData: any[]) => {
    return portfoliosData?.reduce((hiddenKeys: string[], data: any) => {
      Object.keys(data.value).forEach((key) => {
        if (data.value[key].hidden) {
          hiddenKeys.push(key);
        }
      });
      return hiddenKeys;
    }, []);
  };

  const getMergedPortfolioData = async (
    portfolioId: string = creditWidgetData?.portfolio_id,
    hiddenColumns: string[] = [],
  ) => {
    setIsLoading(true);
    const columnsToHide = [...hiddenColumns];
    const columnsToHideQuery = columnsToHide
      .map(
        (column: string | number) =>
          `columnsToHide=${encodeURIComponent(column)}`,
      )
      .join('&');

    axios
      .get(
        `${
          config.ER_ML_URL
        }/portfolio/${portfolioId}?page=${currentPage}&required_calculated_file=${
          searchQuery !== '' ? 'True' : isCalculated ? 'True' : 'False'
        }&is_single_file=${
          creditWidgetData?.selectedDataSourcesWidget?.length === 1
            ? 'True'
            : 'False'
        }&${columnsToHideQuery}`,
      )
      .then(({ data }) => {
        setIsLoading(false);
        let updateRes = [...creditWidgetPortfolioData];

        if (isUpdateAddItem) {
          updateRes = [];
        }

        const responseManipulation =
          searchQuery !== ''
            ? [...(data?.data?.data || {})]
            : isCalculated && currentPage === 1
              ? [...(data?.data?.data || {})]
              : [...updateRes, ...(data?.data?.data || {})];

        if (!creditWidgetData?.widgetReferenceId) {
          addPortfolioCreditWidgetData({
            ...creditWidgetData,
            widgetReferenceId: uuidv4(),
          });
        }

        // const responseManipulation = [
        //   ...creditWidgetPortfolioData,
        //   ...data?.data?.data,
        // ];
        setIsUpdateAddItem(false);
        setCreditWidgetPortfolioData(responseManipulation);
      })
      .catch((err) => {
        setIsLoading(false);
        if (isAxiosError(err)) {
          showModal(
            (err.response?.data as { error?: string })?.error ||
              'Unexpected Axios error',
          );
        } else {
          showModal('something went wrong');
        }
      });
  };

  const toggle = (tab: React.SetStateAction<string>) => {
    if (widgetCurrentActiveTab !== tab) setWidgetCurrentActiveTab(tab);
  };

  const handleExport = (formatType: string) => {
    setIsLoading(true);
    axios
      .get(
        `${config.ER_ML_URL}/portfolio/fetch/${
          creditWidgetData?.portfolio_id
        }?required_calculated_file=${
          searchQuery !== '' ? 'True' : isCalculated ? 'True' : 'False'
        }`,
      )
      .then(({ data }) => {
        setIsLoading(false);

        const responseManipulation = data?.data?.data;

        exportToCSV(responseManipulation, 'exported_data', formatType);
      })
      .catch((err) => {
        setIsLoading(false);
        if (isAxiosError(err)) {
          showModal(
            (err.response?.data as { error?: string })?.error ||
              'Unexpected Axios error',
          );
        } else {
          showModal('something went wrong');
        }
      });
  };

  React.useEffect(() => {
    setWidgetName(creditWidgetData?.customWidgetName);
  }, []);

  React.useEffect(() => {
    if (chatQueryData !== '' && widgetReferenceIdValue === creditIndex) {
      setIsLoading(true);
      addChatQuery('');
      // setIsCalculated(false);
      const payload = {
        portfolio_id: creditWidgetData?.portfolio_id,
        user_query: chatQueryData,
        model_id: selectedModelChat,
        widgetReferenceId: creditWidgetData?.widgetReferenceId,
      };
      axios({
        method: 'post',
        url: `${config.ER_ML_URL}/portfolio/calculate`,
        data: payload,
      })
        .then(({ data }) => {
          setIsCalculated(data?.is_calculated);
          if (data?.is_calculated) {
            setCreditWidgetPortfolioData([]);
            setCurrentPage(1);
            setIsRefresh(!isRefresh);
          }

          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          showModal(err?.response?.data?.error);
        });
    }
  }, [
    widgetReferenceIdValue === creditIndex &&
      chatQueryData !== '' &&
      chatQueryData,
  ]);

  const getSearchedData = () => {
    setIsLoading(true);
    const payload = {
      portfolio_id: creditWidgetData?.portfolio_id,
      user_query: searchQuery,
    };
    axios({
      method: 'post',
      url: `${config.ER_ML_URL}/portfolio/global-selection`,
      data: payload,
    })
      .then(() => {
        if (creditWidgetData?.portfolio_id) {
          const hiddenColumns = getHiddenColumns(
            creditWidgetData?.portfoliosData,
          );
          getMergedPortfolioData(creditWidgetData?.portfolio_id, hiddenColumns);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showModal(err?.response?.data?.error);
      });
  };

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setDivHeight(entry.contentRect.height);
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  React.useEffect(() => {
    if (
      creditWidgetData?.portfolio_id &&
      searchQuery &&
      widgetReferenceIdValue === creditIndex
    ) {
      getSearchedData();
    }
  }, [debouncedUserInput]);

  React.useEffect(() => {
    if (creditWidgetData?.portfolio_id) {
      const hiddenColumns = getHiddenColumns(creditWidgetData?.portfoliosData);
      getMergedPortfolioData(creditWidgetData?.portfolio_id, hiddenColumns);
    }
  }, [creditWidgetData?.portfolio_id, currentPage, isRefresh]);
  return (
    <>
      {isLoading && <WidgetLoader title="" />}
      {/* remove class when open chat widget `widget-pagination wp-bottom` */}
      <div className={`common-widget ${!isChatOpen && 'wp-bottom'}`}>
        {/* Added `cwh-tabs` class if tabs inn widget full screen header */}
        <div className="common-widget-header cwh-tabs">
          <div className="common-widget-header-title">
            <h2>
              {!isWidgetNameChanged ? (
                widgetName ? (
                  widgetName
                ) : (
                  'Credit (My Credit Calc Workbench)'
                )
              ) : (
                <div className="flexbox-c gap-12">
                  <input
                    type="text"
                    placeholder="Enter new widget name"
                    className="form-control form-control-sm mw-260 no-drag"
                    value={widgetName}
                    onChange={(e) => {
                      setWidgetName(e.target.value);
                    }}
                  />
                  <button
                    className="btn-clear flexbox-c gap-1 text-info no-drag"
                    onClick={() => {
                      setIsWidgetNameChanged(false);
                    }}
                  >
                    <IconCross fill="#36567D" />
                    Cancel
                  </button>
                  <button
                    className="btn-clear flexbox-c gap-1 text-primary no-drag"
                    onClick={() => {
                      setIsWidgetNameChanged(false);
                    }}
                  >
                    <IconTick />
                    Save
                  </button>
                </div>
              )}
            </h2>

            {widgetFullScreen && (
              <div className="ms-6 no-drag">
                {/* Create Component Dropdown  */}
                <WidgetMenuDropdown
                  type="CREDIT"
                  dropdownOpen={dropdownOpen}
                  toggleDropDown={toggleDropDown}
                  toggleSendWidgetAsTaskModal={toggleSendWidgetAsTaskModal}
                  toggleShareExternallyModal={toggleShareExternallyModal}
                  toggleExportWidgeTDataModal={toggleExportWidgeTDataModal}
                  toggleRemoveWidgetModal={toggleRemoveWidgetModal}
                  savedType={savedType}
                  toggleIsNormalize={toggleIsNormalize}
                  setIsWidgetNameChanged={setIsWidgetNameChanged}
                />
              </div>
            )}

            {!widgetFullScreen && (
              <div className="d-flex aling-items-center ms-12">
                <button
                  id={`fs${creditIndex}`}
                  type="button"
                  className="btn-icon no-drag"
                  onClick={() => {
                    toggleWidgetFullScreen('add');
                    addWidgetReferenceId(creditIndex);
                    addWidgetType('CREDIT');
                    addIndependentWidgetReferenceId(
                      creditWidgetData?.widgetReferenceId,
                    );
                  }}
                >
                  <IconFullScreen />
                </button>
                <button
                  type="button"
                  className={
                    isChatOpen
                      ? 'icon-active btn-icon no-drag'
                      : 'btn-icon no-drag'
                  } // apply `icon-active` class for active widget chat
                  onClick={() => {
                    toogleFilterModal();
                    setIsChatOpen(!isChatOpen);
                    addWidgetReferenceId(creditIndex);
                    addWidgetType('CREDIT');
                    addIndependentWidgetReferenceId('');
                    if (
                      widRank &&
                      widRank.widgetRank &&
                      widRank?.widgetRank?.slots?.length > 0
                    ) {
                      callTelemetryRecord(
                        'widgets',
                        widRank?.widgetRank?.event_id,
                        widRank?.widgetRank?.slots[0]?.id,
                        ['chat'],
                      );
                    }
                  }}
                >
                  <IconChatbot index={'stocks'} />
                </button>
              </div>
            )}
            {/* flexbox */}
            {widgetFullScreen && (
              <div className="tabs-menu-line mb-0 ms-auto">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: widgetCurrentActiveTab === 'WidgetData',
                      })}
                      onClick={() => {
                        toggle('WidgetData');
                      }}
                    >
                      Widget Data
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: widgetCurrentActiveTab === 'WidgetDataSources',
                      })}
                      onClick={() => {
                        toggle('WidgetDataSources');
                      }}
                    >
                      Widget Data Sources
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            )}
          </div>

          <div className="form-group form-icon mb-0">
            <span className="input-icon">
              <IconSearch />
            </span>
            <input
              id="searchCreditData"
              type="text"
              placeholder="Search"
              className="form-control no-drag"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>

          {widgetFullScreen && (
            <div className="common-widget-header-action">
              <button
                id={`fsrem${creditIndex}`}
                type="button"
                className="btn-icon no-drag"
                onClick={() => {
                  toggleWidgetFullScreen('remove');
                  setWidgetCurrentActiveTab('WidgetData');
                  removeIndependentWidgetReferenceId();
                }}
              >
                <Iconclose />
              </button>
            </div>
          )}

          {!widgetFullScreen && (
            <div className="common-widget-header-action no-drag">
              {/* Create Component Dropdown  */}
              <WidgetMenuDropdown
                type="CREDIT"
                dropdownOpen={dropdownOpen}
                toggleDropDown={toggleDropDown}
                toggleSendWidgetAsTaskModal={toggleSendWidgetAsTaskModal}
                toggleShareExternallyModal={toggleShareExternallyModal}
                toggleExportWidgeTDataModal={toggleExportWidgeTDataModal}
                toggleRemoveWidgetModal={toggleRemoveWidgetModal}
                savedType={savedType}
                toggleIsNormalize={toggleIsNormalize}
                setIsWidgetNameChanged={setIsWidgetNameChanged}
              />
            </div>
          )}
        </div>
        <div
          ref={divRef}
          className="common-widget-content infinite-widget-content"
          onClick={() => {
            if (
              widRank &&
              widRank.widgetRank &&
              widRank?.widgetRank?.slots?.length > 0
            ) {
              callTelemetryRecord(
                'widgets',
                widRank?.widgetRank?.event_id,
                widRank?.widgetRank?.slots[0]?.id,
                ['click'],
              );
            }
          }}
        >
          {/* Widget Chat */}

          {/* Chat True Show*/}
          {isChatOpen && !widgetFullScreen && (
            <div className="widget-chat">
              <PublicChat
                widgetIndex={creditIndex}
                widgetReferenceId={creditWidgetData?.widgetReferenceId}
              />
            </div>
          )}

          {/* Chat True Hide */}
          {!isChatOpen && (
            <TabContent activeTab={widgetCurrentActiveTab}>
              <TabPane tabId="WidgetData">
                <div
                  id={`scrollableDiv-credit-${creditIndex}`}
                  className="scrollbar-hide"
                  style={{
                    height: divHeight,
                    overflow: 'auto',
                    overflowAnchor: 'none',
                  }}
                >
                  <InfiniteScroll
                    dataLength={creditWidgetPortfolioData?.length}
                    next={() => setCurrentPage(currentPage + 1)}
                    hasMore={
                      creditWidgetPortfolioData?.length <= 0 ? false : true
                    }
                    loader={
                      creditWidgetPortfolioData?.length <= 0 ? (
                        <div style={{ paddingLeft: '12px' }}>Loading...</div>
                      ) : null
                    }
                    scrollableTarget={`scrollableDiv-credit-${creditIndex}`}
                  >
                    <div className="table-h-xl">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th style={{ width: '40px' }} scope="col">
                              <div className="flexbox-align-c form-check fc-clear no-drag">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="flexCheckChecked"
                                />
                              </div>
                            </th>

                            {creditWidgetPortfolioData?.length > 0 &&
                              Object?.keys(creditWidgetPortfolioData[0])?.map(
                                (header) => (
                                  <th
                                    style={{ width: '170px' }}
                                    scope="col"
                                    key={header}
                                  >
                                    {header}
                                    <span className="sortable asc">
                                      <IconSort />
                                    </span>
                                  </th>
                                ),
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {creditWidgetPortfolioData?.map(
                            (cValue: any, cIndex: number) => (
                              <tr key={`credit-index-${cIndex}`}>
                                <td>
                                  <div className="flexbox-align-c form-check fc-clear no-drag">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexCheckChecked"
                                    />
                                  </div>
                                </td>
                                {Object?.keys(
                                  creditWidgetPortfolioData[0],
                                )?.map((header) => (
                                  <td key={header}>{cValue[header]}</td>
                                ))}
                              </tr>
                            ),
                          )}
                        </tbody>
                      </table>
                    </div>
                  </InfiniteScroll>
                </div>
                {/* <PublicPagination /> */}
              </TabPane>
              <TabPane tabId="WidgetDataSources">
                <div className="widget-tabs-content">
                  <div className="addwidget-wrapper">
                    <div className="container container-1024">
                      <div className="addwidget-header">
                        <h4 className="title-20 text-center">
                          Add or remove data sources you’d like to expose to
                          your widget
                        </h4>
                      </div>
                      <div className="addwidget-content mw-100 edit-widget">
                        <div className="row row-divider justify-content-center">
                          <div className="col-12 col-lg-6">
                            <div className="d-flex align-items-center">
                              <p className="widget-category me-1 mb-0">
                                PORTFOLIO(S)
                              </p>
                              <span className="d-flex" id="tooltipnewitem13">
                                <IconInfo />
                              </span>
                              <CustomTooltip
                                placement="top"
                                target="tooltipnewitem13"
                              />

                              <button
                                className="ms-3 btn-clear link no-drag"
                                onClick={() => {
                                  setWidgetIndex(creditIndex);
                                  setWidgetTypeToUpdate('CREDIT');
                                  toggleWidgetWorkflow();
                                  setIsUpdateAddItem(true);
                                  document.body.classList.toggle(
                                    'show-dataitem',
                                  );
                                  document.body.classList.remove(
                                    'widget-fullscreen',
                                  );
                                }}
                              >
                                Add item
                              </button>
                            </div>

                            <div className="create-widget create-widget-portfolio">
                              <div className="widget-item">
                                <div className="row row-cols-1">
                                  {creditWidgetData?.selectedDataSourcesWidget
                                    ?.filter(
                                      (x: selectedDataSourceType) =>
                                        x.type === 'PORTFOLIO',
                                    )
                                    ?.map((dVal: selectedDataSourceType) => (
                                      <div className="col">
                                        <input
                                          type="checkbox"
                                          name="select-portfolio"
                                          className="showonly"
                                        />
                                        <div className="widget-card">
                                          <p>{dVal?.file}</p>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="d-flex align-items-center">
                              <p className="widget-category me-1 mb-0">
                                Data Source(S)
                              </p>
                              <span className="d-flex" id="tooltipnewitem14">
                                <IconInfo />
                              </span>
                              <CustomTooltip
                                placement="top"
                                target="tooltipnewitem14"
                              />
                              <button
                                className="ms-3 btn-clear link no-drag"
                                onClick={() => {
                                  setWidgetIndex(creditIndex);
                                  setWidgetTypeToUpdate('CREDIT');
                                  toggleWidgetWorkflow();
                                  setIsUpdateAddItem(true);
                                  document.body.classList.toggle(
                                    'show-dataitem',
                                  );
                                  document.body.classList.remove(
                                    'widget-fullscreen',
                                  );
                                }}
                              >
                                Add item
                              </button>
                            </div>
                            <div className="create-widget create-widget-portfolio">
                              <div className="widget-item">
                                <div className="row row-cols-1">
                                  {creditWidgetData?.selectedDataSourcesWidget
                                    ?.filter(
                                      (x: selectedDataSourceType) =>
                                        x.type === 'DATA_SOURCE',
                                    )
                                    ?.map((dVal: selectedDataSourceType) => (
                                      <div className="col">
                                        <input
                                          type="checkbox"
                                          name="select-portfolio"
                                        />
                                        <div className="widget-card">
                                          <div className="flexbox-c gap-6">
                                            <IconDataSource />
                                            <p>{dVal?.file}</p>

                                            {/* <span
                                            className="d-flex zindex-1"
                                            id="tooltipadditem10"
                                          >
                                            <IconStar />
                                          </span>
                                          <CustomTooltip
                                            placement="top"
                                            target="tooltipadditem10"
                                          />
                                      */}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row row-divider justify-content-center">
                          <div className="col-12 col-lg-6">
                            <div className="d-flex align-items-center">
                              <p className="widget-category me-1 mb-0">
                                KNOWLEDGE BASE ITEMS
                              </p>
                              <span className="d-flex" id="tooltipnewitem13">
                                <IconInfo />
                              </span>
                              <CustomTooltip
                                placement="top"
                                target="tooltipnewitem13"
                              />
                              <button
                                className="ms-3 btn-clear link no-drag"
                                onClick={() => {
                                  setWidgetIndex(creditIndex);
                                  setWidgetTypeToUpdate('CREDIT');
                                  toggleWidgetWorkflow();
                                  setIsUpdateAddItem(true);
                                  document.body.classList.toggle(
                                    'show-dataitem',
                                  );
                                  document.body.classList.remove(
                                    'widget-fullscreen',
                                  );
                                }}
                              >
                                Add item
                              </button>
                            </div>
                            <div className="create-widget create-widget-portfolio">
                              <div className="widget-item">
                                <div className="row row-cols-1">
                                  {creditWidgetData?.selectedDataSourcesWidget
                                    ?.filter(
                                      (x: selectedDataSourceType) =>
                                        x.type === 'KNOWLEDGE_BASE',
                                    )
                                    ?.map((dVal: selectedDataSourceType) => (
                                      <div className="col">
                                        <input
                                          type="checkbox"
                                          name="select-portfolio"
                                        />
                                        <div className="widget-card">
                                          <p>{dVal?.file}</p>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="d-flex align-items-center">
                              <p className="widget-category me-1 mb-0">
                                AUGMENTATION Data Source(S)
                              </p>
                              <span className="d-flex" id="tooltipnewitem14">
                                <IconInfo />
                              </span>
                              <CustomTooltip
                                placement="top"
                                target="tooltipnewitem14"
                              />
                              <button
                                className="ms-3 btn-clear link no-drag"
                                onClick={() => {
                                  setWidgetIndex(creditIndex);
                                  setWidgetTypeToUpdate('CREDIT');
                                  toggleWidgetWorkflow();
                                  setIsUpdateAddItem(true);
                                  document.body.classList.toggle(
                                    'show-dataitem',
                                  );
                                  document.body.classList.remove(
                                    'widget-fullscreen',
                                  );
                                }}
                              >
                                Add item
                              </button>
                            </div>
                            <div className="create-widget create-widget-portfolio">
                              <div className="widget-item">
                                <div className="row row-cols-1">
                                  {creditWidgetData?.selectedDataSourcesWidget
                                    ?.filter(
                                      (x: selectedDataSourceType) =>
                                        x.type === 'AUGMENTATION_DATA_SOURCE',
                                    )
                                    ?.map((dVal: selectedDataSourceType) => (
                                      <div className="col">
                                        <input
                                          type="checkbox"
                                          name="select-portfolio"
                                        />
                                        <div className="widget-card">
                                          <div className="flexbox-c gap-6">
                                            <IconDataSource />
                                            <p>{dVal?.file}</p>
                                            {/* <span
                                              className="d-flex zindex-1"
                                              id="tooltipadditem16"
                                            >
                                              <IconStar />
                                            </span>
                                            <CustomTooltip
                                              placement="top"
                                              target="tooltipadditem16"
                                            /> */}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="gap-12 d-flex justify-content-center no-drag">
                        <button className="btn btn-primary" disabled>
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          )}
        </div>

        {isNormalize && (
          <UpdateMapPortfolioData
            modal={isNormalize}
            toggle={toggleIsNormalize}
            combinedArray={creditWidgetData?.combinedArray}
            portfoliosDataArray={creditWidgetData?.portfoliosData}
            widgetId={creditWidgetData?.id}
            allFileNames={creditWidgetData?.allFileNames}
            allPortfolios={creditWidgetData?.allPortfolios}
            setIsRefresh={setIsRefresh}
            isRefresh={isRefresh}
            rowPreviewData={creditWidgetData?.rowPreviewData}
            availableAliasName={creditWidgetData?.availableAliasName}
            creditWidgetReduxData={creditWidgetData}
          />
        )}

        {sendWidgetAsTaskModal && (
          <SendWidgetAsTask
            type="CREDIT WIDGET"
            modal={sendWidgetAsTaskModal}
            toggle={toggleSendWidgetAsTaskModal}
            data={creditWidgetPortfolioData}
            portfolioId={creditWidgetData?.portfolio_id}
          />
        )}

        {shareExternallyModal && (
          <ShareWidgetExternally
            modal={shareExternallyModal}
            toggle={toggleShareExternallyModal}
            type="CREDIT WIDGET"
            portfolioId={creditWidgetData?.portfolio_id}
          />
        )}

        {exportWidgeTDataModal && (
          <ExportWidgeTData
            modal={exportWidgeTDataModal}
            toggle={toggleExportWidgeTDataModal}
            handleExport={handleExport}
          />
        )}

        {removeWidgetModal && (
          <RemoveWidget
            modal={removeWidgetModal}
            toggle={toggleRemoveWidgetModal}
            removeWidget={removeCreditWidget}
          />
        )}

        {/* {showSessionModal && (
          <SessionModal
            modal={showSessionModal}
            toggle={toggleShowSessionModal}
            // fileKey={fileKey}
          />
        )} */}
      </div>
    </>
  );
}
