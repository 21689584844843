import { groupBy, isArray, isEmpty, keys, map, orderBy, split } from 'lodash';
import moment from 'moment';
import AIassistantGIF from '../../../../assets/images/ai-assistant.gif';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ScrollableFeed from 'react-scrollable-feed';
// import ImgAIAvatar from '../../../../assets/images/ai-avatar.png';
import { chatData } from '../../../redux/selector/ChatSelector';
import { Button } from 'reactstrap';
import { typingData } from '../../../redux/selector/TypingSelector';
import { userData } from '../../../redux/selector/UserSelector';
import { widgetTypeData } from '../../../redux/selector/WidgetTypeSelector';
import useAction from '../../hooks/useAction';
import { useNavigate } from 'react-router';
import {
  IconCross,
  IconThumbsDown,
  IconThumbsUp,
  IconDuplicate,
} from '../Icons';
import { Tooltip } from 'reactstrap';
import axios from 'axios';
import config from '../../../../config';
import { isAxiosError } from '../../../redux/constant';
import { independentWidgetReferenceIdData } from '../../../redux/selector/WidgetReferenceIdSelector';
// import { allPortfolioWidgetsSelector } from '../../../redux/selector/PortfolioWidgetsSelector';
// import { message } from 'antd';

const Messages = ({
  socket,
  setWebSocketConnection,
  selectedModal,
  activeTab,
  widgetReferenceId,
  allPortfolioWidgetReduxData,
}: any) => {
  const {
    updateAllChatMessages,
    addNewMessage,
    showModal,
    addPortfolioVisualizationWidgetData,
  } = useAction();
  const user = useSelector(userData);
  const [WebSocket, setWebSocket] = useState(socket);
  const [thinkingTime, setThinkingTime] = useState(0);
  const [feedbackValue, setFeedbackValue] = useState('');
  const [typingStatus, setTypingStatus] = useState('');
  const [currentTime, setCurrentTime] = useState(moment().format('LT'));
  const chatMsg = useSelector(chatData);
  const widgetName = useSelector(widgetTypeData);
  const typingStatusData = useSelector(typingData);
  const [isSaving, setIsSaving] = useState(false);

  // tooltip
  // const { item, id } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [activeTarget, setActiveTarget] = useState<string | null>(null);
  const [feedbackType, setFeedbackType] = useState('');
  const [submittedFeedbackIds, setSubmittedFeedbackIds] = useState<
    Array<number>
  >([]);
  const [selectedMsgId, setSelectedMsgId] = useState<number>(0);
  const independentWidgetReferenceIdValue = useSelector(
    independentWidgetReferenceIdData,
  );

  const navigate = useNavigate();

  const toggle = (targetId: string) => {
    if (activeTarget === targetId) {
      setTooltipOpen(!tooltipOpen);
    } else {
      setActiveTarget(targetId);
      setTooltipOpen(true);
    }
  };

  const handleSubmitFeedback = async (msgId: number) => {
    try {
      const payload: {
        type: number;
        feedback: string;
      } = {
        type: Number(feedbackType),
        feedback: feedbackValue,
      };

      await axios({
        method: 'post',
        url: `${config.ER_ML_URL}/feedback/`,
        data: payload,
      });
      let tempIds = [...submittedFeedbackIds];

      tempIds.push(msgId);

      setSubmittedFeedbackIds(tempIds);
      setSelectedMsgId(0);
      setFeedbackType('');
      setFeedbackValue('');
      setActiveTarget(null);
      setTooltipOpen(false);
      showModal('Sent', true);
    } catch (err) {
      if (isAxiosError(err)) {
        showModal(
          (err.response?.data as { error?: string })?.error ||
            'Unexpected Axios error',
        );
      } else {
        showModal('something went wrong');
      }
    }
  };

  useEffect(() => {
    setTypingStatus(typingStatusData);
  }, [typingStatusData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format('LT'));
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  let isMainChatBot = 'False';

  if (widgetName === 'MAIN') {
    isMainChatBot = 'True';
  }

  const handleJoinConversation = () => {
    WebSocket.emit('getMessages', {
      page: 1,
      limit: 1000,
      widgetReferenceId: widgetReferenceId,
      isMainChatBot: isMainChatBot,
    });

    WebSocket.on('messages', ({ messages }: any) => {
      const formattedData = orderBy(messages, ['created'], ['asc']);
      const dateSeperatorAdded = map(formattedData, (item) => {
        const copyItem = { ...item };
        copyItem['createdDate'] = split(copyItem.created, 'T')[0];
        return copyItem;
      });
      const messageFilteredObj = groupBy(dateSeperatorAdded, 'createdDate');
      updateAllChatMessages(messageFilteredObj);
    });
  };

  const getNewMessages = () => {
    WebSocket.on('sendMessages', ({ data }: any) => {
      const copyMessage = { ...data };
      copyMessage['createdDate'] = split(copyMessage.created, 'T')[0];
      copyMessage['isHistory'] = data.isHistory;
      copyMessage['isChat'] = data.isChat;

      if (activeTab === 'CHAT') {
        localStorage.setItem('latestMessage', JSON.stringify(copyMessage));
      }

      addNewMessage(copyMessage);
    });
  };

  useEffect(() => {
    if (WebSocket !== null) {
      if (isEmpty(chatMsg)) {
        handleJoinConversation();
      }
      getNewMessages();

      return () => {
        WebSocket.off('sendMessages');
      };
    } else {
      const newSocket = setWebSocketConnection();
      setWebSocket(newSocket);
    }
  }, [WebSocket]);

  const handleSaveMessage = async (message: any) => {
    setIsSaving(true);

    try {
      const response = await axios({
        method: 'post',
        url: `${config.ER_ML_URL}/langchain/saveChat`,
        data: message,
      });

      if (response.status === 200) {
        showModal(
          'Chat Saved. Check out Local Storage for export options',
          true,
        );
      } else {
        showModal('Failed to save chat. Contact Admin', false);
      }
    } catch (error) {
      showModal('Error while saving chat. Please try again later.', false);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    let interval: any;
    if (
      typingStatus === 'PREPARE' ||
      typingStatus === 'CALL_LLM' ||
      typingStatus === 'CALL_ROBOTICS'
    ) {
      setThinkingTime(0);
      interval = setInterval(() => {
        setThinkingTime((prevTime) => prevTime + 10);
      }, 10);
    }
    return () => clearInterval(interval);
  }, [typingStatus]);

  // useEffect(() => {
  //   if (feedbackType) {
  //     toggle();
  //   }
  // }, [feedbackType]);

  const handleAddChartBuilder = async (chartId: string) => {
    if (chartId) {
      const url = `${config.ER_ML_URL}/portfolio/get-chatbot-charts`;

      const response = await axios({
        method: 'get',
        url: url,
        params: { chartId: chartId },
      });

      if (response.status === 200) {
        const chartsConfiguration = response?.data;
        addPortfolioVisualizationWidgetData({
          id: allPortfolioWidgetReduxData?.length,
          value: 'embedded_browser',
          widgetType: 'PORTFOLIO',
          ...chartsConfiguration,
        });
        document.body.classList.remove('show-dataitem');
        navigate(`/portfolio`);
      } else {
        showModal('Failed to fetch', false);
      }
    }
  };

  return (
    <>
      <ScrollableFeed className="sw-content" forceScroll>
        <div className="receiver-mainbox message-box">
          <div className="message-inner-box thumbs-hover-show">
            <div className="receiver-avatar">
              <img src={AIassistantGIF} className="img-avatar" alt="" />
            </div>
            <div className="about-message">
              <div className="message-time">
                <p className="lh-1">
                  <span>
                    {currentTime} •{' '}
                    {selectedModal ? selectedModal : 'Add AI Keys'}
                  </span>
                </p>
              </div>
              <div className="message-description">
                <p>
                  Hi {user?.name}! <br />
                  What can I help you with today?
                </p>
                <div className="flexbox-c gap-8 mt-1 thumbs-btn">
                  <button className="btn btn-icon btn-20">
                    <IconThumbsUp />
                  </button>
                  <button className="btn btn-icon btn-20">
                    <IconThumbsDown />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isArray(keys(chatMsg)) &&
          keys(chatMsg).map((itemMsgGroup) => {
            return (
              <React.Fragment key={itemMsgGroup}>
                {chatMsg[itemMsgGroup]?.filter(
                  (a: any) =>
                    (widgetName === '' ||
                    widgetName === 'LOAN_FINANCIAL' ||
                    widgetName === 'RECEIVABLE' ||
                    widgetName === 'NPL_FINANCIAL' ||
                    widgetName === 'MAIN'
                      ? widgetReferenceId
                        ? a?.widgetReferenceId === widgetReferenceId
                        : a?.widgetReferenceId ===
                          independentWidgetReferenceIdValue
                      : a?.widget_type?.toUpperCase() === widgetName) &&
                    (activeTab === 'CHAT' || activeTab === 'HISTORY'
                      ? true
                      : a?.isHistory),
                )?.length > 0 && (
                  <div className="day-date-box">
                    <h3 className="day-date-label">
                      <span className="line-divider"></span>
                      <span className="date-text">
                        {moment(itemMsgGroup).format('DD MMM YYYY')}
                      </span>
                      <span className="line-divider"></span>
                    </h3>
                  </div>
                )}

                {chatMsg[itemMsgGroup]
                  ?.filter(
                    (a?: any) =>
                      (widgetName === '' ||
                      widgetName === 'LOAN_FINANCIAL' ||
                      widgetName === 'RECEIVABLE' ||
                      widgetName === 'NPL_FINANCIAL'
                        ? widgetReferenceId
                          ? a?.widgetReferenceId === widgetReferenceId
                          : a?.widgetReferenceId ===
                            independentWidgetReferenceIdValue
                        : a?.widget_type?.toUpperCase() === widgetName) &&
                      (activeTab === 'CHAT' ? a?.isChat : a?.isHistory),
                  )
                  ?.map((dateMsg?: any) => (
                    <>
                      {dateMsg?.from_id === user.user_id ? (
                        <div className="sender-mainbox message-box">
                          <div className="message-inner-box">
                            <div className="message-time">
                              <p>
                                <span>
                                  {moment(dateMsg?.created).format('LT')}
                                </span>
                              </p>
                            </div>
                            <div className="message-description">
                              {(dateMsg?.message || dateMsg?.text)
                                ?.split('\n')
                                .map((chatText?: any, key?: any) => (
                                  <p key={key}>{chatText}</p>
                                ))}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="receiver-mainbox message-box">
                          <div className="message-inner-box thumbs-hover-show">
                            <div className="receiver-avatar">
                              <img
                                src={AIassistantGIF}
                                className="img-avatar"
                                alt=""
                              />
                              {/* <span></span> */}
                            </div>
                            <div className="about-message">
                              <div className="message-time">
                                <p>
                                  {/* Exchange Chatbot{' '} */}
                                  <span>
                                    {moment(dateMsg?.created).format('LT')}
                                  </span>
                                  <span> - {dateMsg?.model_name}</span>
                                </p>
                              </div>
                              <div className="message-description">
                                {(dateMsg?.message || dateMsg?.text)
                                  ?.split('\n')
                                  .map((chatText?: any, key?: any) => (
                                    <p key={key}>{chatText}</p>
                                  ))}
                                {(dateMsg?.message || dateMsg?.text) ===
                                'Please Click below to view it on Chart Builder' ? (
                                  <Button
                                    color="primary"
                                    onClick={() =>
                                      handleAddChartBuilder(dateMsg?.chartId)
                                    }
                                  >
                                    Open Chart Builder
                                  </Button>
                                ) : null}
                                <div className="flexbox-c gap-8 mt-1 thumbs-btn">
                                  {!submittedFeedbackIds?.includes(
                                    dateMsg?.id,
                                  ) && (
                                    <div className="flexbox-c gap-8 mt-1 thumbs-btn">
                                      <button
                                        className="btn btn-icon btn-20"
                                        id={'thumb-up-' + dateMsg?.id}
                                        onClick={() => {
                                          toggle('thumb-up-' + dateMsg?.id);

                                          setFeedbackType('1');
                                          setSelectedMsgId(dateMsg?.id);
                                        }}
                                      >
                                        <IconThumbsUp />
                                      </button>
                                      <button
                                        className="btn btn-icon btn-20"
                                        id={'thumb-down-' + dateMsg?.id}
                                        onClick={() => {
                                          toggle('thumb-down-' + dateMsg?.id);
                                          setFeedbackType('0');
                                          setSelectedMsgId(dateMsg?.id);
                                        }}
                                      >
                                        <IconThumbsDown />
                                      </button>

                                      {activeTarget &&
                                        document.getElementById(
                                          activeTarget,
                                        ) && (
                                          <Tooltip
                                            trigger="click"
                                            isOpen={tooltipOpen}
                                            placement="top"
                                            target={activeTarget}
                                            toggle={() => toggle(activeTarget)}
                                            className="tooltip-light"
                                          >
                                            <div className="text-end">
                                              <button
                                                className="btn btn-icon btn-20 ms-auto"
                                                onClick={() =>
                                                  setTooltipOpen(false)
                                                }
                                              >
                                                <IconCross />
                                              </button>
                                            </div>
                                            <div className="form-group text-start mb-3 mt-2">
                                              <input
                                                type="text"
                                                placeholder="Send feedback"
                                                className="form-control"
                                                value={feedbackValue}
                                                style={{ minWidth: '250px' }}
                                                onChange={(e) =>
                                                  setFeedbackValue(
                                                    e.target.value,
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="text-end mb-2">
                                              <button
                                                className="btn btn-primary"
                                                onClick={() =>
                                                  handleSubmitFeedback(
                                                    selectedMsgId,
                                                  )
                                                }
                                              >
                                                Send
                                              </button>
                                            </div>
                                          </Tooltip>
                                        )}
                                    </div>
                                  )}
                                  <div className="flexbox-c gap-8 mt-1 thumbs-btn">
                                    <button
                                      className="btn btn-icon btn-20"
                                      id={'save-' + dateMsg?.id}
                                      onClick={() => {
                                        handleSaveMessage(dateMsg);
                                      }}
                                    >
                                      <IconDuplicate />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}

                {isSaving && (
                  <div className="loader-overlay">
                    <img
                      src={AIassistantGIF}
                      alt="Saving..."
                      className="loader-gif"
                    />
                  </div>
                )}
                {(widgetName === 'MAIN' || widgetName === 'EMBEDDED_BROWSER') &&
                  (typingStatus === 'PREPARE' ||
                    typingStatus === 'CALL_LLM' ||
                    typingStatus === 'CALL_ROBOTICS') &&
                  moment().format('YYYY-MM-DD') === itemMsgGroup && (
                    <div className="receiver-mainbox message-box msg-type">
                      <div className="message-inner-box">
                        <div className="receiver-avatar">
                          <img
                            src={AIassistantGIF}
                            className="img-avatar"
                            alt=""
                          />
                        </div>
                        <div className="message-description">
                          Thinking... [{(thinkingTime / 1000).toFixed(2)}s]
                        </div>
                      </div>
                    </div>
                  )}
              </React.Fragment>
            );
          })}
      </ScrollableFeed>
    </>
  );
};

export default Messages;
