import { FieldArray, Formik } from 'formik';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import useAction from '../../../../components/hooks/useAction';
import {
  IconCross,
  IconEye,
  IconEyeHide,
  IconTick,
} from '../../../../components/public/Icons';
import MarketPlaceService from '../../../../services/marketplace.service';
import { useState } from 'react';
import { EndpointDataTypes } from '../addDataMarketplace/step-2/AddSubDataSet';
import { get, isEmpty } from 'lodash';
import CustomSelect from '../../../../components/ui/CustomSelect';
import { object, string } from 'yup';
import AppLoader from '../../../../components/loaders/AppLoader';

type BuildConnectionPayloadTypes = {
  url: string;
  userName?: string;
  password?: string;
  secretKey?: string;
  secretKeyName?: string;
  authURL?: string;
  isOAuthRequired: boolean;
  sourceId: string;
  scope?: string;
  grantType?: string;
  requestMethod?: string;
  requestDataBody?: string;
  searchParameter?: string;
  parameters?: {
    key: string;
    value: string;
  }[];
};

type AddNewDataItemModalProps = {
  modal: boolean;
  toggle: () => void;
  sourceId: string;
  onSuccess: () => void;
  endpointData?: EndpointDataTypes;
  onCloseWithoutSave: () => void;
};

type SavePayloadTypes = {
  url: string;
  userName?: string;
  password?: string;
  isOAuthRequired: boolean;
  isAmplifyData: boolean;
  isChainedCall?: boolean;
  scope?: string;
  grantType?: string;
  secretKey?: string;
  secretKeyName?: string;
  authURL?: string;
  sourceId: string;
  endpointName: string;
  endpointDescription?: string;
  requestMethod?: string;
  requestDataBody?: string;
  searchParameter?: string;
  parameters?: {
    key: string;
    value: string;
  }[];
};

type SavePayloadTypesUpdate = {
  url: string;
  userName?: string;
  password?: string;
  secretKey?: string;
  isOAuthRequired: boolean;
  scope?: string;
  grantType?: string;
  isAmplifyData: boolean;
  isChainedCall?: boolean;
  secretKeyName?: string;
  sourceId: string;
  authURL?: string;
  serviceId: string;
  endpointName: string;
  endpointDescription?: string;
  requestMethod?: string;
  requestDataBody?: string;
  searchParameter?: string;
  parameters?: {
    key: string;
    value: string;
  }[];
};

const requestOptions = [
  {
    label: 'GET',
    value: 'get',
  },
  {
    label: 'POST',
    value: 'post',
  },
];

const marketplaceService = new MarketPlaceService();

const AddNewDataItemModal = ({
  modal,
  toggle,
  sourceId,
  onSuccess,
  endpointData,
  onCloseWithoutSave,
}: AddNewDataItemModalProps) => {
  const { showModal } = useAction();

  const [isBuildConnectionRunning, setIsBuildConnectionRunning] =
    useState(false);
  const [isBuildConnection, setIsBuildConnection] = useState(false);
  const [isBuildConnectionError, setIsBuildConnectionError] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParameterValue, setSeachParameterValue] = useState('');

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  // const options = [
  //   { label: 'Data Source', value: 'DATA_SOURCE' },
  //   { label: 'Augumentation data source', value: 'AUGMENTED_DATA_SOURCE' },
  //   { label: 'News source', value: 'NEWS_SOURCES' },
  //   { label: 'Foundational modals', value: 'FOUNDATIONAL_MODALS' },
  // ];

  const closeBtn = (
    <button
      className="close"
      onClick={() => {
        onCloseWithoutSave();
        toggle();
      }}
      type="button"
    >
      <IconCross fill="#36567D" />
    </button>
  );
  const validateJson = (value: string) => {
    try {
      JSON.parse(value);
      return true;
    } catch {
      return false;
    }
  };

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader toggle={toggle} close={closeBtn}>
        Add New Endpoint
      </ModalHeader>
      <Formik
        initialValues={{
          //common for build connection and save endpoint
          url: get(endpointData, 'serviceUrl', ''),
          userName: get(endpointData, 'userName', ''),
          password: get(endpointData, 'password', ''),
          secretKey: get(endpointData, 'secretKey', ''),
          secretKeyName: get(endpointData, 'secretKeyName', ''),
          authURL: get(endpointData, 'authURL', ''),
          serviceId: get(endpointData, 'id', ''),
          sourceId: get(endpointData, 'sourceId', sourceId),
          endpointName: get(endpointData, 'endpointName', ''),
          endpointDescription: get(endpointData, 'endpointDescription', ''),
          parameters: get(endpointData, 'parameters', [
            { key: '', value: '', iSearchParameter: false },
          ]),

          isOAuthRequired: get(endpointData, 'isOAuthRequired', false),
          scope: get(endpointData, 'scope', ''),
          grantType: get(endpointData, 'grantType', ''),
          requestMethod:
            get(endpointData, 'requestMethod') === 'post'
              ? { label: 'POST', value: 'post' }
              : { label: 'GET', value: 'get' },
          requestDataBody:
            get(endpointData, 'requestMethod') === 'post'
              ? get(endpointData, 'requestDataBody', '')
              : '',
          isRequestPost: endpointData
            ? get(endpointData, 'requestMethod') === 'post'
              ? true
              : false
            : false,
          isAmplifyData: get(endpointData, 'isAmplifyData', false),
          isChainedCall: get(endpointData, 'isChainedCall', false),
        }}
        validationSchema={object({
          requestDataBody: string().when(
            ['isRequestPost'],
            (values, schema) => {
              const isRequestPost = values[0]; // Access the first value in the array
              return isRequestPost
                ? schema.test('is-json', 'Invalid JSON', (value) =>
                    validateJson(value || ''),
                  )
                : schema;
            },
          ),
        })}
        onSubmit={(values, { resetForm }) => {
          if (!isBuildConnection) {
            setIsBuildConnectionRunning(true);
            setIsBuildConnection(false);
            setIsBuildConnectionError(false);

            //call api here
            const payload: BuildConnectionPayloadTypes = {
              url: values.url,
              userName: values.userName,
              password: values.password,
              sourceId: values.sourceId,

              isOAuthRequired: values.isOAuthRequired,
              scope: values.scope,
              grantType: values.grantType,

              requestMethod: values.requestMethod.value,
              requestDataBody: values.requestDataBody,

              secretKey: values.secretKey,
              secretKeyName: values.secretKeyName,
              authURL: values.authURL,
              parameters: values.parameters.map(
                (x: { key: string; value: string }) => {
                  return {
                    key: x.key,
                    value: x.value,
                  };
                },
              ),
            };

            if (payload.requestMethod === 'get') {
              delete payload.requestDataBody;
            }

            if (!payload.secretKey) {
              delete payload.secretKey;
            }

            if (!payload.secretKeyName) {
              delete payload.secretKeyName;
            }

            if (!payload.isOAuthRequired) {
              delete payload.scope;
              delete payload.grantType;
            }

            if (
              !payload.parameters![0]?.key &&
              !payload.parameters![0]?.value
            ) {
              delete payload.parameters;
            }

            if (!payload.isOAuthRequired && !payload.userName) {
              delete payload.authURL;
            }

            if (!payload.userName) {
              delete payload.userName;
              delete payload.password;
            }

            marketplaceService
              .buildConnection(payload)
              .then(() => {
                showModal('Field Updated!', true);
                setIsBuildConnection(true);
                setIsBuildConnectionError(false);
                setIsBuildConnectionRunning(false);
              })
              .catch((err) => {
                showModal(err?.response?.data?.message);
                setIsBuildConnection(false);
                setIsBuildConnectionError(true);
                setIsBuildConnectionRunning(false);
              });
          }

          if (isBuildConnection && isEmpty(endpointData)) {
            const payload: SavePayloadTypes = {
              url: values.url,
              userName: values.userName,
              password: values.password,

              isOAuthRequired: values.isOAuthRequired,
              scope: values.scope,
              grantType: values.grantType,

              requestMethod: values.requestMethod.value,
              requestDataBody: values.requestDataBody,

              isAmplifyData: values.isAmplifyData,
              isChainedCall: values.isChainedCall,
              // searchParameter: searchParameterValue,

              secretKey: values.secretKey,
              secretKeyName: values.secretKeyName,
              authURL: values.authURL,
              sourceId: values.sourceId,
              endpointName: values.endpointName,
              endpointDescription: values.endpointDescription,
              parameters: values.parameters.map(
                (x: { key: string; value: string }) => {
                  return {
                    key: x.key,
                    value: x.value,
                  };
                },
              ),
            };

            if (payload.requestMethod === 'get') {
              delete payload.requestDataBody;
            }

            if (searchParameterValue) {
              payload.searchParameter = searchParameterValue;
            }

            if (!payload.secretKey) {
              delete payload.secretKey;
            }

            if (!payload.secretKeyName) {
              delete payload.secretKeyName;
            }

            if (!payload.isOAuthRequired) {
              delete payload.scope;
              delete payload.grantType;
            }
            if (
              !payload.parameters![0]?.key &&
              !payload.parameters![0]?.value
            ) {
              delete payload.parameters;
            }

            if (!payload.isOAuthRequired && !payload.userName) {
              delete payload.authURL;
            }

            if (!payload.userName) {
              delete payload.userName;
              delete payload.password;
            }

            if (!payload.endpointDescription) {
              delete payload.endpointDescription;
            }
            setIsLoading(true);

            marketplaceService
              .addSourceEndpoint(payload)
              .then(() => {
                toggle();
                setIsLoading(false);
                onCloseWithoutSave();
                showModal('Added successfully!', true);
                onSuccess();
                resetForm();
                setIsBuildConnection(false);
                setIsBuildConnectionError(false);
                setIsBuildConnectionRunning(false);
              })
              .catch((err) => {
                showModal(err?.response?.data?.error);
                setIsLoading(false);
              });
          }

          if (isBuildConnection && !isEmpty(endpointData)) {
            const payload: SavePayloadTypesUpdate = {
              url: values.url,
              userName: values.userName,
              password: values.password,

              isOAuthRequired: values.isOAuthRequired,
              scope: values.scope,
              grantType: values.grantType,

              requestMethod: values.requestMethod.value,
              requestDataBody: values.requestDataBody,

              isAmplifyData: values.isAmplifyData,
              isChainedCall: values.isChainedCall,
              sourceId: values.sourceId,
              // searchParameter: searchParameterValue,

              secretKey: values.secretKey,
              secretKeyName: values.secretKeyName,
              authURL: values.authURL,
              serviceId: values.serviceId,
              endpointName: values.endpointName,
              endpointDescription: values.endpointDescription,
              parameters: values.parameters.map(
                (x: { key: string; value: string }) => {
                  return {
                    key: x.key,
                    value: x.value,
                  };
                },
              ),
            };

            if (payload.requestMethod === 'get') {
              delete payload.requestDataBody;
            }

            if (searchParameterValue) {
              payload.searchParameter = searchParameterValue;
            }

            if (!payload.secretKey) {
              delete payload.secretKey;
            }

            if (!payload.secretKeyName) {
              delete payload.secretKeyName;
            }

            if (!payload.isOAuthRequired) {
              delete payload.scope;
              delete payload.grantType;
            }

            if (
              !payload.parameters![0]?.key &&
              !payload.parameters![0]?.value
            ) {
              delete payload.parameters;
            }

            if (!payload.isOAuthRequired && !payload.userName) {
              delete payload.authURL;
            }

            if (!payload.userName) {
              delete payload.userName;
              delete payload.password;
            }

            if (!payload.endpointDescription) {
              delete payload.endpointDescription;
            }

            setIsLoading(true);

            marketplaceService
              .updateEndpoint(payload)
              .then(() => {
                toggle();
                onCloseWithoutSave();
                showModal('Updated successfully!', true);
                onSuccess();
                resetForm();
                setIsBuildConnection(false);
                setIsBuildConnectionError(false);
                setIsBuildConnectionRunning(false);
                setIsLoading(false);
              })
              .catch((err) => {
                showModal(err?.response?.data?.error);
                setIsLoading(false);
              });
          }
        }}
      >
        {(renderProps) => {
          const {
            values,
            touched,
            errors,
            setFieldValue,
            handleSubmit,
            handleChange,
            handleBlur,
          } = renderProps;
          return (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <div className="form-group">
                  <p className="font-12 mb-0">*All fields are required</p>
                </div>
                <div className="mb-scroll">
                  <div className="mbs-content">
                    <div className="row row-g-20">
                      <div className="col-12 col-lg-6">
                        <div className="form-group mb-3">
                          <div className="form-check">
                            <input
                              id="isAmplifyData"
                              type="checkbox"
                              className="form-check-input"
                              checked={values.isAmplifyData}
                              onChange={() => {
                                setFieldValue(
                                  'isAmplifyData',
                                  !values.isAmplifyData,
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="isAmplifyData"
                            >
                              Is Downloadable Link Data
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-6">
                        <div className="form-group mb-3">
                          <div className="form-check">
                            <input
                              id="isChainedCall"
                              type="checkbox"
                              className="form-check-input"
                              checked={values.isChainedCall}
                              onChange={() => {
                                setFieldValue(
                                  'isChainedCall',
                                  !values.isChainedCall,
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="isChainedCall"
                            >
                              Is First Authorization Token
                            </label>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label className="form-label">Data parent name</label>
                          <p className="mb-0">The Warren Group</p>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label className="form-label">Data Item Type</label>
                          <p className="mb-0">Data Source</p>
                        </div>
                      </div> */}
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label className="form-label">Endpoint Name</label>
                          <input
                            id="endpointName"
                            type="text"
                            placeholder="Enter name"
                            className="form-control"
                            value={values.endpointName}
                            onChange={(e) => {
                              setFieldValue('endpointName', e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group">
                          <label className="form-label">
                            Endpoint Description (optional)
                          </label>
                          <input
                            id="endpointDescription"
                            type="text"
                            placeholder="Enter description"
                            className="form-control"
                            value={values.endpointDescription}
                            onChange={(e) => {
                              setFieldValue(
                                'endpointDescription',
                                e.target.value,
                              );
                            }}
                          />
                          {/* <CustomSelect
                        options={options}
                        value={values.serviceType}
                        isClearable
                        onChange={(e: { label: string; value: string }) => {
                          setFieldValue('serviceType', e);
                        }}
                      /> */}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                Request Method
                              </label>
                              <CustomSelect
                                options={requestOptions}
                                value={values.requestMethod}
                                isClearable
                                onChange={(e: {
                                  label: string;
                                  value: string;
                                }) => {
                                  setFieldValue('requestMethod', e);
                                  setFieldValue(
                                    'isRequestPost',
                                    e.value === 'post' ? true : false,
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-9">
                            <div className="form-group">
                              <label className="form-label">Endpoint URL</label>
                              <input
                                id="url"
                                type="text"
                                placeholder="Enter URL"
                                className="form-control"
                                // defaultValue={'https://api.refinitiv.com/'}
                                disabled={isBuildConnectionRunning}
                                value={values.url}
                                onChange={(e) => {
                                  setFieldValue('url', e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {values.requestMethod.value === 'post' && (
                        <div className="col-12">
                          <div className="form-group">
                            <label className="form-label">Request Body</label>
                            <textarea
                              id="requestDataBody"
                              className="form-control"
                              placeholder="Enter Json here"
                              value={values.requestDataBody}
                              onChange={(e) => {
                                setFieldValue(
                                  'requestDataBody',
                                  e.target.value,
                                );
                              }}
                            />
                            {touched.requestDataBody &&
                            errors.requestDataBody ? (
                              <div className="text-danger pt-1">
                                {errors.requestDataBody}
                              </div>
                            ) : (
                              touched.requestDataBody && (
                                <div className="text-success pt-1">
                                  Valid JSON
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}

                      <div className="col-12">
                        <div className="col-12 col-lg-6 d-flex">
                          <div className="form-group mb-3">
                            <label className="form-label">Authentication</label>
                            <div className="form-check mt-1">
                              <input
                                id="isOAuthRequired"
                                type="checkbox"
                                className="form-check-input"
                                checked={values.isOAuthRequired}
                                onChange={() => {
                                  setFieldValue(
                                    'isOAuthRequired',
                                    !values.isOAuthRequired,
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="isOAuthRequired"
                              >
                                is OAuth
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group mb-3">
                          <input
                            id="username"
                            type="text"
                            placeholder="Enter username (optional)"
                            className="form-control"
                            disabled={isBuildConnectionRunning}
                            autoComplete="off"
                            value={values.userName}
                            onChange={(e) => {
                              setFieldValue('userName', e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-group mb-3">
                          <div className="form-icon form-icon-end">
                            <span
                              className="input-icon"
                              onClick={togglePasswordVisibility}
                            >
                              {isPasswordVisible ? (
                                <IconEye width={16} height={16} />
                              ) : (
                                <IconEyeHide width={16} height={16} />
                              )}
                            </span>
                            <input
                              id="password"
                              type={isPasswordVisible ? 'text' : 'password'}
                              placeholder="Enter password (optional)"
                              className="form-control"
                              disabled={isBuildConnectionRunning}
                              value={values.password}
                              autoComplete="new-password"
                              onChange={(e) => {
                                setFieldValue('password', e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {((values.userName && values.password) ||
                        values.isOAuthRequired) && (
                        <div className="col-12">
                          <div className="form-group mb-3">
                            <input
                              id="authURL"
                              type="text"
                              placeholder="Enter Auth URL"
                              className="form-control"
                              disabled={isBuildConnectionRunning}
                              value={values.authURL}
                              onChange={(e) => {
                                setFieldValue('authURL', e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {values.isOAuthRequired && (
                        <div className="col-12">
                          <div className="form-group mb-3">
                            <label className="form-label">Scope</label>
                            <input
                              id="scope"
                              type="text"
                              placeholder="Enter scope"
                              className="form-control"
                              disabled={isBuildConnectionRunning}
                              value={values.scope}
                              onChange={(e) => {
                                setFieldValue('scope', e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {values.isOAuthRequired && (
                        <div className="col-12">
                          <div className="form-group mb-3">
                            <label className="form-label">Grant Type</label>
                            <input
                              id="grantType"
                              type="text"
                              placeholder="Enter grantType"
                              className="form-control"
                              disabled={isBuildConnectionRunning}
                              value={values.grantType}
                              onChange={(e) => {
                                setFieldValue('grantType', e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label className="form-label">unique API key</label>
                          <input
                            id="secretKey"
                            type="text"
                            placeholder="Enter unique API key"
                            className="form-control"
                            disabled={isBuildConnectionRunning}
                            value={values.secretKey}
                            onChange={(e) => {
                              setFieldValue('secretKey', e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label className="form-label">unique key name</label>
                          <input
                            id="secretKeyName"
                            type="text"
                            placeholder="Enter unique key name"
                            className="form-control"
                            disabled={isBuildConnectionRunning}
                            value={values.secretKeyName}
                            onChange={(e) => {
                              setFieldValue('secretKeyName', e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-2">
                      <label className="form-label mb-0">
                        Additional parameters - Checkbox for Search Parameter
                      </label>
                    </div>

                    <FieldArray
                      name="parameters"
                      render={({ remove, push }) => (
                        <>
                          {values?.parameters?.map(
                            (
                              param: {
                                key: string;
                                value: string;
                                iSearchParameter: boolean;
                              },
                              paramIndex: number,
                            ) => (
                              <>
                                <div
                                  className="d-flex gap-2 align-items-center"
                                  key={paramIndex}
                                >
                                  <div className="form-group mb-3">
                                    <div className="form-check">
                                      <input
                                        id={`iSearchParameter-${paramIndex}`}
                                        type="checkbox"
                                        className="form-check-input"
                                        name={`parameters.${paramIndex}.iSearchParameter`}
                                        checked={
                                          param.iSearchParameter || false
                                        }
                                        onChange={() => {
                                          const updatedParameters =
                                            values.parameters.map(
                                              (p: any, index: any) => ({
                                                ...p,
                                                iSearchParameter:
                                                  index === paramIndex
                                                    ? !p.iSearchParameter
                                                    : false,
                                              }),
                                            );
                                          setFieldValue(
                                            'parameters',
                                            updatedParameters,
                                          );
                                          if (!param.iSearchParameter) {
                                            setSeachParameterValue(param.key);
                                          } else {
                                            setSeachParameterValue('');
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 col-lg-4">
                                    <div className="form-group mb-3">
                                      <input
                                        id="paramKey"
                                        type="text"
                                        placeholder="Key"
                                        className="form-control"
                                        name={`parameters.${paramIndex}.key`}
                                        value={param.key}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-7">
                                    <div className="form-group mb-3 flexbox">
                                      <input
                                        id="paramValue"
                                        type="text"
                                        placeholder="Value"
                                        className="form-control"
                                        name={`parameters.${paramIndex}.value`}
                                        value={param.value}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <button
                                        className="btn-icon btn-16 btn mx-3"
                                        type="button"
                                        onClick={() => {
                                          remove(paramIndex);
                                        }}
                                      >
                                        <IconCross fill="#36567D" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {values.parameters?.length - 1 ===
                                  paramIndex && (
                                  <div className="form-group mb-0">
                                    <button
                                      className="btn-clear link"
                                      type="button"
                                      onClick={() => {
                                        push({
                                          key: '',
                                          value: '',
                                          isSearchParameter: false,
                                        });
                                      }}
                                    >
                                      Add Parameter
                                    </button>
                                  </div>
                                )}
                              </>
                            ),
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="flexbox">
                <div>
                  {isBuildConnectionError && (
                    <p className="mb-0 text-info">
                      There was an error or no response. Please try again.
                    </p>
                  )}
                  {isBuildConnectionRunning && (
                    <p className="text-warning mb-0">
                      Building connection - please wait...
                    </p>
                  )}
                  {isBuildConnection && (
                    <p className="text-primary mb-0 flexbox-c">
                      <IconTick />
                      Connection OK
                    </p>
                  )}
                </div>
                <div className="flexbox-c gap-12">
                  <button
                    className="btn btn-outline-info"
                    type="button"
                    onClick={() => {
                      onCloseWithoutSave();
                      toggle();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={`btn btn-primary ${
                      isBuildConnectionRunning && 'btn-loading'
                    }`}
                    // use `btn-loading` class for show loader
                    // onClick={() => {
                    // saveData(values);
                    // }}
                  >
                    <span className="btn-text">
                      {' '}
                      {isBuildConnection ? 'Add Endpoint' : 'Build Connection'}
                    </span>
                  </button>
                </div>
              </ModalFooter>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddNewDataItemModal;
