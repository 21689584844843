import {
  IconAddWidget,
  IconChat,
  IconCross,
  IconDropdownArrow,
  IconFullScreen,
} from './Icons';
//import { useState, useEffect } from 'react';
//import io from 'socket.io-client';
import { JSX } from 'react';
import useAction from '../hooks/useAction';
import MessageInput from './messages/MessageInput';
import Messages from './messages/Messages';
//import { useSelector } from 'react-redux';
//import { widgetTypeData } from '../../redux/selector/WidgetTypeSelector';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import WebSocketHoc from '../../Hoc/WebSocketHoc';
import AddPortfolioWidget from '../../containers/client/portfolio/addportfoliowidget/AddPortfolioWidget';
// import AddNewDataItemModal from '../../containers/client/marketplace/modals/AddNewDataItemModal';
import CustomSelect from '../ui/CustomSelect';
import { useSelector } from 'react-redux';
import { marketplaceTabSelector } from '../../redux/selector/MarketplaceTabSelector';
import AddDashboardWidget from '../../containers/client/dashboard/addDashboardWidget/AddDashboardWidget';
import AddNewsfeedWidget from '../../containers/client/newsfeed/addNewsfeedWidget/AddNewsfeedWidget';
import AddDataMarketplace from '../../containers/client/marketplace/addDataMarketplace/AddDataMarketplace';
// import ImgNemotron from '../../../assets/images/nemotron.png';
// import ImgOrca2 from '../../../assets/images/orca2.png';
// import ImgClaudeAI from '../../../assets/images/claudeai.png';
// import ImgGemini from '../../../assets/images/gemini.png';
import axios from 'axios';
import config from '../../../config';
import { userDetailsData } from '../../redux/selector/UserDetailsSelector';
import { userData } from '../../redux/selector/UserSelector';

type ModelListTypes = {
  _id: string;
  model_name: string;
  display_name: string;
};

const toogleFilterModal = () => {
  document.body.classList.toggle('has-overlay');
  document.body.classList.toggle('show-chat');
};
const toogleCloseModal = () => {
  document.body.classList.remove('has-overlay');
  document.body.classList.remove('show-chat');
};

const PublicChat = ({
  webSocket,
  setWebSocketConnection,
  toggleAddDataItemModal,
  addDataItemModal,
  widgetReferenceId,
  widgetIndex,
  getFoundationalModelListMarketplace,
}: any) => {
  const {
    addWidgetType,
    removeWidgetReferenceId,
    showModal,
    addSelectedModel,
  } = useAction();
  const [activeTab, setActiveTab] = useState('CHAT');
  const [widgetModal, setWidgetModal] = useState(false);
  const toggleWidget = () => setWidgetModal(!widgetModal);
  const marketplaceSelectedTab = useSelector(marketplaceTabSelector);
  const user = useSelector(userData);
  const [modelList, setModelList] = useState<
    Array<{
      label: JSX.Element;
      value: string;
    }>
  >([]);
  const [selectedModelList, setSelectedModelList] = useState(
    {} as {
      label: JSX.Element;
      value: string;
    },
  );
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const userDetails = useSelector(userDetailsData);

  const getFoundationalModelList = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: `${config.ER_ML_URL}/llm/list`,
      });
      const responseManipulation: ModelListTypes[] = response?.data?.data || [];
      if (responseManipulation?.length > 0) {
        let updatedRes = responseManipulation?.map(x => {
          return {
            label: (
              <div className="select-img">
                <span>{x?.display_name}</span>
              </div>
            ),
            value: x?.display_name,
          };
        });

        setModelList(updatedRes);
        setSelectedModelList(updatedRes[0]);
        addSelectedModel(updatedRes[0]?.value);
      }
    } catch (error) {
      showModal('something went wrong');
    }
  };

  useEffect(() => {
    if (userDetails?._id) getFoundationalModelList();
  }, [userDetails?._id]);

  return (
    <>
      {/* <div className="overlay"></div> */}
      <div className="action-sticky">
        {location?.pathname?.includes('marketplace') &&
          marketplaceSelectedTab !== '5' && (
            <div className="as-item">
              <button
                type="button"
                className="btn btn-icon"
                onClick={toggleAddDataItemModal}
              >
                <span className="as-icon">
                  <IconAddWidget />
                </span>
              </button>
              <span className="as-text">Data Item</span>
            </div>
          )}

        {(location?.pathname?.includes('dashboard') ||
          location?.pathname?.includes('portfolio') ||
          location?.pathname?.includes('newsfeed') ||
          location?.pathname?.includes('products')) && (
          <div className="as-item">
            <button
              type="button"
              className="btn btn-icon"
              onClick={() => {
                toggleWidget();
                document.body.classList.toggle('show-dataitem');
              }}
            >
              <span className="as-icon">
                <IconAddWidget />
              </span>
            </button>
            <span className="as-text">Widget</span>
          </div>
        )}

        {(location?.pathname?.includes('dashboard') ||
          location?.pathname?.includes('portfolio') ||
          location?.pathname?.includes('newsfeed') ||
          location?.pathname?.includes('products')) &&
          user?.userDetail?.license?.licenseType !== 'BASIC' && (
            <div className="as-item">
              <button
                type="button"
                className="btn btn-icon"
                onClick={() => {
                  toogleFilterModal();
                  addWidgetType('MAIN');
                }}
              >
                <span className="as-icon">
                  <IconChat />
                </span>
                <span className="as-text">Chat with AI</span>
              </button>
            </div>
          )}
      </div>

      <div
        className={classNames('sidebar-widget sw-chat', {
          'full-screen': isFullScreen,
        })}
      >
        <div className="sw-header">
          <div className="tabs-menu-line flexbox">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classNames({ active: activeTab === 'CHAT' })}
                  onClick={() => {
                    setActiveTab('CHAT');
                  }}
                >
                  Chat
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({ active: activeTab === 'HISTORY' })}
                  onClick={() => {
                    setActiveTab('HISTORY');
                  }}
                >
                  History
                </NavLink>
              </NavItem>
            </Nav>
            {modelList?.length > 0 && (
              <div className="ai-tools mb-0 chat-header-ai-tools">
                <div className="select-clear">
                  <CustomSelect
                    options={modelList}
                    value={selectedModelList}
                    isSearchable={false}
                    render
                    onChange={(e: { label: JSX.Element; value: string }) => {
                      setSelectedModelList(e);
                      addSelectedModel(e?.value || '');
                    }}
                  />
                  <div className="arrow-icon">
                    <IconDropdownArrow />
                  </div>
                </div>
              </div>
            )}
          </div>
          <button
            type="button"
            className="btn sw-close-btn"
            onClick={toggleFullScreen}
          >
            <IconFullScreen width={24} height={24} fill="#36567D" />
          </button>

          <button
            type="button"
            className="btn sw-close-btn"
            onClick={() => {
              toogleCloseModal();
              removeWidgetReferenceId();
            }}
          >
            <IconCross width={24} height={24} fill="#36567D" />
          </button>
        </div>
        {modelList?.length > 0 && (
          <div className="ai-tools chat-ai-tools">
            <div className="select-clear">
              <CustomSelect
                options={modelList}
                value={selectedModelList}
                isSearchable={false}
                render
                onChange={(e: { label: JSX.Element; value: string }) => {
                  setSelectedModelList(e);
                  addSelectedModel(e?.value || '');
                }}
              />
              <div className="arrow-icon">
                <IconDropdownArrow />
              </div>
            </div>
          </div>
        )}
        {webSocket ? (
          <>
            <Messages
              socket={webSocket}
              setWebSocketConnection={setWebSocketConnection}
              selectedModal={selectedModelList.value}
              activeTab={activeTab}
              widgetReferenceId={widgetReferenceId}
            />
            <MessageInput
              socket={webSocket}
              activeTab={activeTab}
              widgetReferenceId={widgetReferenceId}
              widgetIndex={widgetIndex}
              selectedModal={selectedModelList.value}
            />
          </>
        ) : (
          <div className="sw-content">Not Connected</div>
        )}
      </div>

      {widgetModal && (
        <>
          {location.pathname.includes('portfolio') && (
            <AddPortfolioWidget
              togglePortfolioWidget={toggleWidget}
              widgetIdOfIndex={null}
              isUpdate={false}
            />
          )}
          {location.pathname.includes('dashboard') && (
            <AddDashboardWidget toggleDashboardWidget={toggleWidget} />
          )}
          {location.pathname.includes('newsfeed') && (
            <AddNewsfeedWidget toggleNewsfeedWidget={toggleWidget} />
          )}
        </>
      )}

      {addDataItemModal && (
        <AddDataMarketplace
          toggleAddDataMarketplace={toggleAddDataItemModal}
          getFoundationalModelList={getFoundationalModelListMarketplace}
        />
      )}
    </>
  );
};

export default WebSocketHoc(PublicChat);
